import { getPlayerProfile, uploadPlayerFile, getPlayerFiles, updatePlayerProfile, uploadHeadshot } from '../services/apiService'; // Import the API service
import { Box, snackbar, Typography, Grid, Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, FormControl, Radio, RadioGroup, FormGroup, Snackbar, Alert, FormControlLabel, Checkbox, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';






const PlayerDetailsPage = () => {
  const { playerId } = useParams(); // Get the playerId from the URL
  const navigate = useNavigate();
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track errors
  const [approved, setApproved] = useState(false);
  const [selectedSports, setSelectedSports] = React.useState([]);
  const [playerFiles, setPlayerFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileType, setFileType] = useState('waiver'); // Default file type
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  // Snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'info'


 useEffect(() => {
  const fetchPlayerData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Fetch player profile
      const playerData = await getPlayerProfile(playerId);
      setSelectedPlayer(playerData);
      console.log(playerData); // Check if SignedDocumentURL exists in the API response


      // Fetch player files
      try {
        const response = await getPlayerFiles(playerId);
        setPlayerFiles(response.files);
      } catch (err) {
        if (err.response?.status === 404) {
          // Handle no files found scenario
          setPlayerFiles([]); // Set an empty array if no files are found
        } else {
          throw err; // Rethrow other errors
        }
      }

      // Update other player-specific states
      setApproved(playerData.Approved);
    } catch (err) {
      setError('Error fetching player data.');
      console.error(err);
    } finally {
      setLoading(false);
      setIsLoading(false); // Ensures compatibility with your current loading state
    }
  };

  fetchPlayerData();
}, [playerId]);

const handleFileTypeChange = (event) => {
  setFileType(event.target.value);
};



 // Handle file upload from drag-and-drop
  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      setSnackbarMessage('No file selected.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }


  const file = acceptedFiles[0]; // Assuming single file upload
  // const fileType = 'other'; // Specify the file type

   try {
      await uploadPlayerFile(playerId, file, fileType);
      const updatedFiles = await getPlayerFiles(playerId); // Refresh file list
      setPlayerFiles(updatedFiles.files);
      setSnackbarMessage('File uploaded successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbarMessage('Failed to upload file.');
      setSnackbarSeverity('error');
    } finally {
      setOpenSnackbar(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


   // Handle snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleHeadshotRedirect = () => {
    navigate('/headshot-upload'); // Redirect to /headshot-upload
  };


  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6">Loading Player Data...</Typography>
      </Box>
    );
  }

  // Handle sport selection
const handleSportChange = (isChecked, sport) => {
  setSelectedSports((prevSports) =>
    isChecked ? [...prevSports, sport] : prevSports.filter((s) => s !== sport)
  );
};

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  if (!selectedPlayer) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: 'background.default',
        }}
      >
        <Typography variant="h6">No Player Selected</Typography>
      </Box>
    );
  }

  // const handleApproval = () => {
  //   console.log('Player Approved');
  //   setApproved(true); // Update the state to approved
  // };
  const handleApproval = async () => {
    try {
      const updatedData = { Approved: true, Active: true }; // Payload to set Approved and Active to true
      await updatePlayerProfile(playerId, updatedData); // API call
  
      setApproved(true); // Update UI state to reflect approval
      console.log('Player Approved, Active, and Profile Updated');
    } catch (error) {
      console.error('Error updating player profile:', error);
    }
  };

  return (
    <Box
    sx={{
      minHeight: '100vh',
      bgcolor: '#F4F6F8',
      padding: '1rem',
    }}
  >
    {/* Back Button */}
    <IconButton
      onClick={() => navigate(-1)}
      sx={{
        position: 'absolute',
        top: '4rem',
        right: '1rem',
        color: '#1976d2',
        backgroundColor: '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
        borderRadius: '50%',
        padding: '8px',
      }}
    >
      <ArrowBackIcon />
    </IconButton>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 2,
        p: 3,
      }}
    >
{/* Merged Header and Personal Information Section */}
<Box
  sx={{
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: { xs: 'column', sm: 'row' },
    mb: 3,
    padding: '16px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '& > *:first-of-type': {
      marginRight: { sm: '32px' },
      marginBottom: { xs: '24px', sm: 0 }  // Add bottom margin on mobile
    }
  }}
>
{/* Headshot Image */}
<div style={{
    position: 'relative',  // Ensure the container has a relative position to hold the icon correctly
    display: 'inline-block',  // Keeps the layout inline
    width: 200, // Fixed size for the image container
    height: 200, // Fixed size for the image container
    borderRadius: '50%', // Round the image container
    backgroundColor: selectedPlayer.HeadshotURL ? 'transparent' : 'black',  // Black background if no image
    overflow: 'hidden',  // Prevent image overflow
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    border: '3px solid #1976d2',
}}>
  <img
    src={selectedPlayer.HeadshotURL || '/placeholder-image.png'}
    alt={`${selectedPlayer.FirstName} ${selectedPlayer.LastName}`}
    style={{
      width: '100%',
      height: '100%',
      objectFit: selectedPlayer.HeadshotURL ? 'cover' : 'contain',  // Fit the image correctly
    }}
  />

  {/* Pen Icon for Upload */}
  <div
    style={{
      position: 'absolute',
      bottom: '10px',  // Move to the bottom of the image
      right: '10px',  // Align to the right edge
      backgroundColor: 'rgba(255, 255, 255, 0.6)',  // Transparent white background for icon visibility
      borderRadius: '50%',
      padding: '8px',  // Slightly larger padding to increase clickable area
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      zIndex: 10,  // Ensure it's on top of the image
    }}
    onClick={() => document.getElementById('imageUploadInput').click()}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="#1976d2"
      style={{ width: 24, height: 24 }}  // Adjust size of the icon
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.232 5.232l3.536 3.536M9 11l6.768-6.768a2.5 2.5 0 113.536 3.536L12.536 14.5a2 2 0 01-.879.513l-3.073.768a1 1 0 01-1.21-1.211l.768-3.072a2 2 0 01.514-.879z"
      />
    </svg>
  </div>

  {/* Hidden File Input */}
  <input
    id="imageUploadInput"
    type="file"
    accept="image/*"
    style={{ display: 'none' }}
    onChange={async (event) => {
      const file = event.target.files[0];

      if (file) {
        try {
          if (!selectedPlayer.PlayerId) {
            throw new Error('No player ID found');
          }

          await uploadHeadshot(selectedPlayer.PlayerId, file);

          setSnackbar({
            open: true,
            message: 'Photo uploaded successfully',
            severity: 'success',
          });

          // Update the Headshot URL in the state
          setSelectedPlayer((prev) => ({
            ...prev,
            HeadshotURL: URL.createObjectURL(file), // For instant preview
          }));
        } catch (error) {
          console.error('Upload failed:', error);
          setSnackbar({
            open: true,
            message: 'Photo upload failed. Please try again.',
            severity: 'error',
          });
        }
      }
    }}
  />
</div>


{/* Personal Information Section */}
<Box
  sx={{
    '& .MuiGrid-item': {
      '& .MuiTypography-body1': {  // Label style
        fontWeight: 'bold',
        mb: 0.5
      },
      '& .MuiTypography-body2': {  // Value field style
        bgcolor: '#f8f8f8',
        p: '8px 12px',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        minHeight: '18px',
        maxWidth: '400px',  // Increased to fit full UUID
        width: '100%'
      }
    }
  }}
>
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="body1">First Name:</Typography>
      <Typography variant="body2">
        {selectedPlayer.FirstName || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" fontWeight="bold">
        Last Name:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.LastName || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" fontWeight="bold">
        Address:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.HomeAddress || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        State:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.State || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        City:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.City || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Zip Code:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.Zip || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Email:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.Email || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Phone:
      </Typography>
      <Typography variant="body2">
        {selectedPlayer.PhoneNumber || 'No Data'}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        PlayerID:
      </Typography>
      <Typography variant="body2">
        {playerId || 'No Data'}
      </Typography>
    </Grid>
  </Grid>
</Box>




   {/* Compliance Review and Approve Button */}
  <Box
    sx={{
      mt: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }}
  >
    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
      Compliance Review
    </Typography>
    <Button
  variant="contained"
  color={approved ? "success" : "primary"}
  sx={{
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: approved ? 'green' : 'grey',
    pointerEvents: approved ? 'none' : 'auto', // Disable click when approved
  }}
  onClick={!approved ? handleApproval : null}
>
  {approved ? 'Approved' : 'Approve'}
</Button>
  </Box>

    </Box>
  </Box>


{/* Government ID Section */}
<Box sx={{ bgcolor: 'background.paper', borderRadius: 2, boxShadow: 2, p: 3, mb: 3 }}>
  {/* Headline Section */}
  <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
    {/* Government ID Headline */}
    <Grid item>
      <Typography variant="h6">Government ID</Typography>
    </Grid>    
  </Grid>
  <Divider sx={{ mb: 2 }} />

  <Grid container spacing={2} alignItems="flex-start">
    {/* Details on the Left */}
    <Grid item xs={12} sm={4}>
      <Grid container spacing={2} sx={{
        '& .MuiTypography-body1': {  // Label style
          fontWeight: 'bold',
          mr: 1,
          minWidth: '140px',  // Fixed width for all labels
        },
        '& .MuiTypography-body2': {  // Value field style
          bgcolor: '#f8f8f8',
          p: '8px 12px',
          borderRadius: '4px',
          border: '1px solid #e0e0e0',
          minHeight: '18px',
          width: '240px'  // Fixed width for all data fields
        }
      }}>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Legal Name:
            </Typography>
            <Typography variant="body2">
              {selectedPlayer.LegalName || 'No Data'}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              ID Type:
            </Typography>
            <Typography variant="body2">
              {selectedPlayer.IDType || 'No Data'}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              ID Number:
            </Typography>
            <Typography variant="body2">
              {selectedPlayer.IDNumber || 'No Data'}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Country/State of Issue:
            </Typography>
            <Typography variant="body2">
              {selectedPlayer.IDCountry || 'No Data'}
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Expiration Date:
            </Typography>
            <Typography variant="body2">
              {selectedPlayer.IDExpirationDate || 'No Data'}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>

    {/* Image in the Center */}
    <Grid
      item
      xs={12}
      sm={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Ensures full height alignment
      }}
    >
      {selectedPlayer.DrivingLicenseURL ? (
        <img
          src={selectedPlayer.DrivingLicenseURL}
          alt="Government ID"
          style={{
            width: '100%',
            maxWidth: '300px',
            height: 'auto',
            borderRadius: '8px',
          }}
        />
      ) : (
        <Typography variant="body2" sx={{ textAlign: 'center' }}>No Government ID Available</Typography>
      )}
    </Grid>

    
  </Grid>
</Box>


      {/* Document Table Section */}
      
      <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, boxShadow: 2, p: 3 }}>
  {/* Player Files Upload Section */}
  <Typography variant="h6" sx={{ mb: 2 }}>
    Player Files
  </Typography>
  
{/* File Type Selection */}
      <Box sx={{ marginBottom: '1rem' }}>
        <Typography variant="subtitle1">Select File Type:</Typography>
        <RadioGroup
          row
          value={fileType}
          onChange={handleFileTypeChange}
        >
          <FormControlLabel value="waiver" control={<Radio />} label="Waiver" />
          <FormControlLabel value="contract" control={<Radio />} label="Contract" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
      </Box>

{/* Drag-and-Drop Upload Section */}
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #1976d2',
          borderRadius: 2,
          p: 4,
          textAlign: 'center',
          bgcolor: isDragActive ? '#e3f2fd' : 'background.paper',
          transition: 'background-color 0.2s ease',
          mb: 3,
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body1" color="primary">
            Drop the files here...
          </Typography>
        ) : (
          <Typography variant="body1" color="textSecondary">
            Drag and drop a file here, or click to select a file
          </Typography>
        )}
      </Box>



 {/* Uploaded Files Table Section */}
<Box
  sx={{
    mt: 3,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 1,
    p: 2,
  }}
>
  <Typography variant="h6" sx={{ mb: 2 }}>
    Uploaded Files
  </Typography>

  <TableContainer>
    <Table size="small" sx={{ 
      minWidth: 650,
      borderCollapse: 'separate',
      borderSpacing: 0,
    }}>
      <TableHead>
        <TableRow sx={{ 
          backgroundColor: '#f5f5f5',  // Light gray header
          '& th': {
            borderBottom: '2px solid #e0e0e0',
            borderTop: '1px solid #e0e0e0',
            fontWeight: 600,
            padding: '12px 16px',
          },
          '& th:first-of-type': { borderLeft: '1px solid #e0e0e0' },
          '& th:last-of-type': { borderRight: '1px solid #e0e0e0' },
        }}>
          <TableCell>File Name</TableCell>
          <TableCell>File Type</TableCell>
          <TableCell>Uploaded At</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={4} align="center" sx={{ 
              borderBottom: '1px solid #e0e0e0',
              borderLeft: '1px solid #e0e0e0',
              borderRight: '1px solid #e0e0e0',
            }}>
              Loading...
            </TableCell>
          </TableRow>
        ) : (
          (() => {
            const allFiles = [
              ...(selectedPlayer.SignedDocumentURL
                ? [
                    {
                      FileName: 'Signed Document',
                      FileType: 'waiver',
                      UploadedAt: selectedPlayer.updatedAt || null,
                      BlobUrl: selectedPlayer.SignedDocumentURL,
                    },
                  ]
                : []),
              ...(Array.isArray(playerFiles) ? playerFiles : []),
            ];

            return allFiles.length > 0 ? (
              allFiles
                .sort((a, b) => new Date(b.UploadedAt) - new Date(a.UploadedAt)) // Sort by 'UploadedAt' in descending order
                .map((file, index) => (
                  <TableRow 
                    key={file.FileId || index}
                    sx={{ 
                      '&:hover': { bgcolor: '#fafafa' },
                      '& td': {
                        borderBottom: '1px solid #e0e0e0',
                        borderLeft: '1px solid #e0e0e0',
                        padding: '12px 16px',
                      },
                      '& td:last-of-type': {
                        borderRight: '1px solid #e0e0e0',
                      },
                    }}
                  >
                    <TableCell>{file.FileName}</TableCell>
                    <TableCell>{file.FileType}</TableCell>
                    <TableCell>
                      {file.UploadedAt
                        ? new Date(file.UploadedAt).toLocaleString('en-US', {
                            dateStyle: 'medium',
                            timeStyle: 'short',
                          })
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{
                          padding: '4px 12px',
                          fontSize: '12px',
                          textTransform: 'none',
                        }}
                        href={file.BlobUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={`View file: ${file.FileName}`}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell 
                  colSpan={4} 
                  align="center"
                  sx={{ 
                    borderBottom: '1px solid #e0e0e0',
                    borderLeft: '1px solid #e0e0e0',
                    borderRight: '1px solid #e0e0e0',
                    py: 3,
                  }}
                >
                  No files uploaded yet.
                </TableCell>
              </TableRow>
            );
          })()
        )}
      </TableBody>
    </Table>
  </TableContainer>
</Box>


{/* Snackbar */}
<Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
</Box>
</Box>


  
  );
};

export default PlayerDetailsPage;
