import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout, getPlayerProfile } from '../services/apiService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [waiverUrl, setWaiverUrl] = useState(null);
  const [hasWaiver, setHasWaiver] = useState(false);

  useEffect(() => {
    const fetchWaiver = async () => {
      try {
        const playerId = localStorage.getItem('playerId');
        if (playerId) {
          const profile = await getPlayerProfile(playerId);
          setWaiverUrl(profile.SignedDocumentURL);
          setHasWaiver(!!profile.SignedDocumentURL);
        }
      } catch (error) {
        console.error('Error fetching waiver:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWaiver();
  }, []);

  const handleReturn = () => {
    setIsLoading(true);
    logout();
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        {hasWaiver ? (
          <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
        ) : null}
        <Typography variant="h4" component="h1" gutterBottom>
          {hasWaiver ? 'Thank You!' : 'No Waiver Found'}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {hasWaiver 
            ? 'Your waiver has been successfully submitted.'
            : 'Please complete a waiver to continue.'}
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 3, mt: 2, width: '100%' }}>
        {hasWaiver ? (
          <>
            <Typography variant="body2" color="text.secondary" paragraph>
              If you need to update any information or have questions about your submission, 
              please contact a PLN administrator for assistance.
            </Typography>
            
            {waiverUrl && (
              <Button 
                variant="outlined"
                href={waiverUrl}
                target="_blank"
                sx={{ mt: 2, mb: 2 }}
                fullWidth
              >
                View Signed Waiver
              </Button>
            )}
          </>
        ) : (
          <Typography variant="body2" color="text.secondary" paragraph>
            You haven't submitted a waiver yet. Please complete one to continue.
          </Typography>
        )}
      </Paper>

      <Box sx={{ mt: 4, display: 'flex', gap: 2 }}>
        {!hasWaiver && (
          <Button 
            variant="contained"
            onClick={() => navigate('/waiver')}
          >
            Complete Waiver
          </Button>
        )}
        
        <Button 
          variant="outlined"
          onClick={handleReturn}
        >
          Return to Login
        </Button>
      </Box>
    </Container>
  );
};

export default ThankYouPage;