import React, { useState, useEffect } from 'react';
import { Container, Typography, IconButton, List, ListItem, ListItemText, ListItemIcon, Switch, Avatar, Box } from '@mui/material';
import { Person, Payment, DriveEta, SportsBaseball, Help, Policy, Edit } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { getPlayerProfile } from '../services/apiService'; // Assuming you have this function to fetch player profile data

const ProfilePage = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [playerProfile, setPlayerProfile] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    HeadshotURL: '' // Add the HeadshotURL field to store the URL
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleToggleDarkMode = () => {
    setDarkMode((prev) => !prev);
  };

  useEffect(() => {
    const roleName = localStorage.getItem('roleName');
    const token = localStorage.getItem('token');

    // If they have a token but no role (waiver case) or are a player, send to player home
    if (!roleName || roleName === 'Player') {
      navigate('/player-home');
    }

    const fetchProfile = async () => {
      const playerId = localStorage.getItem('playerId');
      if (playerId) {
        try {
          const profileData = await getPlayerProfile(playerId);
          setPlayerProfile(profileData);
        } catch (error) {
          setErrorMessage('Failed to load profile data.');
        }
      } else {
        setErrorMessage('No player ID found in localStorage.');
      }
    };
    fetchProfile();
  }, [navigate]);


  // Common styles for the outline of each list item
  const listItemStyle = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginBottom: '10px',
    padding: '10px',
  };

  return (
    <Container maxWidth="sm">
      {/* Profile Section */}
      <Box display="flex" flexDirection="column" alignItems="center" marginBottom={4}>
        {/* Profile Image with Edit Icon */}
        <Box position="relative">
          <Link to="/headshot-upload">
            <Avatar
              alt={`${playerProfile.FirstName} ${playerProfile.LastName}`}
              src={playerProfile.HeadshotURL || '/default-profile-picture.jpg'} // Use the headshot URL or a default image
              sx={{ width: 120, height: 120 }}
            />
          </Link>

          {/* Edit Icon Overlay */}
          <Link to="/headshot-upload">
            <IconButton
              aria-label="edit"
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: '#fff',
                borderRadius: '50%',
                border: '1px solid #ccc',
                padding: '5px',
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Link>
        </Box>

        {/* Player Name and Email */}
        <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
          {playerProfile.FirstName} {playerProfile.LastName}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
          {playerProfile.Email}
        </Typography>
      </Box>

      {/* Settings List */}
      <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2 }}>Settings</Typography>
      <List>
        <ListItem button component={Link} to="/profile" sx={listItemStyle}>
          <ListItemIcon><Person /></ListItemIcon>
          <ListItemText primary="Personal Info" />
        </ListItem>
        <ListItem button sx={listItemStyle}>
          <ListItemIcon><Payment /></ListItemIcon>
          <ListItemText primary="Payment Methods" />
        </ListItem>
        <ListItem button component={Link} to="/upload-license" sx={listItemStyle}>
          <ListItemIcon><DriveEta /></ListItemIcon>
          <ListItemText primary="Driver License" />
        </ListItem>
        <ListItem button component={Link} to="/select-sport" sx={listItemStyle}>
          <ListItemIcon><SportsBaseball /></ListItemIcon>
          <ListItemText primary="Your Sports" />
        </ListItem>
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <Switch checked={darkMode} onChange={handleToggleDarkMode} />
          </ListItemIcon>
          <ListItemText primary="Darkmode" />
        </ListItem>
      </List>

      {/* Other Options */}
      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 3 }}>Other</Typography>
      <List>
        <ListItem button sx={listItemStyle}>
          <ListItemIcon><Help /></ListItemIcon>
          <ListItemText primary="Help Center" />
        </ListItem>
        <ListItem button sx={listItemStyle}>
          <ListItemIcon><Policy /></ListItemIcon>
          <ListItemText primary="Privacy & Policy" />
        </ListItem>
      </List>

      {errorMessage && <Typography color="error" align="center">{errorMessage}</Typography>}
    </Container>
  );
};

export default ProfilePage;
