import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar as MuiToolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { logout } from '../services/apiService';
import { useAuth } from '../AppContext';

// Styled components for the toolbar and logo
const StyledToolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

const Logo = styled('img')({
  height: '50px',
});

const Toolbar = ({ minimal = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [role, setRole] = useState(null);
  const open = Boolean(anchorEl);
  const { setUser } = useAuth();

  useEffect(() => {
    const checkAuth = () => {
      const storedRole = localStorage.getItem('roleName');
      const token = localStorage.getItem('token');
      
      // If no token or role, redirect to login
      if (!token || !storedRole) {
        setUser(null);
        navigate('/login');
        return;
      }
      
      setRole(storedRole);
    };

    checkAuth();
  }, [navigate, setUser]);

  const handleLogout = async () => {
    try {
      setUser(null);
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { text: 'Select Tournament', onClick: () => navigate('/select-tournament') },
    { text: 'Create Playoffs', onClick: () => navigate('/playoff-selection') },
    { text: 'Leaderboard', onClick: () => navigate('/leaderboard') },
    { text: 'Admin', onClick: () => navigate('/players') },
    // { text: 'WaiverLinkGen', onClick: () => navigate('/waiver-links') },
  ];

  const filteredMenuItems = menuItems.filter((item) => {
    return localStorage.getItem('roleName') === 'Internal';
  });

  const renderMenuItems = () => {
    if (minimal) {
      return (
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircle />
          <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </IconButton>
      );
    }
    
    return (
      <>
        {filteredMenuItems.map((item, index) => (
          <Button key={index} color="inherit" onClick={item.onClick} sx={{ mr: 2 }}>
            {item.text}
          </Button>
        ))}
        
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircle />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          <MenuItem onClick={() => navigate('/admin')}>Profile</MenuItem>
          <MenuItem onClick={() => navigate('/change-password')}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <AppBar position="static" color="default" elevation={1}>
      <StyledToolbar>
        <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />

        {!isMobile && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderMenuItems()}
          </div>
        )}

        {isMobile && (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List>
                {filteredMenuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={() => {
                      item.onClick();
                      setDrawerOpen(false);
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                <ListItem onClick={() => navigate('/change-password')}>
                  <ListItemText primary="Change Password" />
                </ListItem>
                <ListItem onClick={handleLogout}>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Drawer>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

export default Toolbar;
