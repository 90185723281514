import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  Avatar, 
  Box,
  Button,
  Collapse,
} from '@mui/material';
import { 
  Person, 
  DriveEta,
  ExpandMore,
  ExpandLess,
  ErrorOutline,
} from '@mui/icons-material';
import { getPlayerProfile } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import CenteredLoading from '../components/CenteredLoading';

const PlayerHomePage = () => {
  const [playerProfile, setPlayerProfile] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    HeadshotURL: '',
    PhoneNumber: '',
    HomeAddress: '',
    State: '',
    PostalCode: '',
    IDNumber: '',
    IDType: '',
    IDStateCountry: '',
    IDExpirationDate: ''
  });
  const [helpExpanded, setHelpExpanded] = useState(false);
  const [privacyExpanded, setPrivacyExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const listItemStyle = {
    border: '1px solid #ccc',
    borderRadius: '24px',
    marginBottom: '10px',
    padding: '12px 20px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  };

  const expandedContentStyle = {
    backgroundColor: '#f8f8f8',
    borderRadius: '16px',
    margin: '0 8px 16px 8px',
    padding: '16px',
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const playerId = localStorage.getItem('playerId');
      if (!playerId) {
        setError('No player ID found');
        return;
      }

      try {
        const profileData = await getPlayerProfile(playerId);
        setPlayerProfile(profileData);
      } catch (error) {
        setError('Failed to load profile data');
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) return <CenteredLoading />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" marginBottom={4}>
        <Avatar
          alt={`${playerProfile.FirstName} ${playerProfile.LastName}`}
          src={playerProfile.HeadshotURL || '/default-profile-picture.jpg'}
          sx={{ width: 120, height: 120 }}
        />
        <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
          {playerProfile.FirstName} {playerProfile.LastName}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
          {playerProfile.Email}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 3 }}>
        <Button
          fullWidth
          variant="contained"
          disabled={true}
          sx={{
            backgroundColor: '#e0e0e0',
            color: '#757575',
            borderRadius: '8px',
            padding: '12px',
            marginBottom: '24px',
            cursor: 'default'
          }}
        >
          No Waiver Available
        </Button>
      </Box>

      <List>
        <ListItem 
          sx={listItemStyle}
          onClick={() => navigate('/player/personal-info')}
          button
        >
          <ListItemIcon><Person /></ListItemIcon>
          <ListItemText 
            primary="Personal Information"
            primaryTypographyProps={{
              style: { fontWeight: 500 }
            }}
          />
        </ListItem>
        <ListItem 
          sx={listItemStyle}
          onClick={() => navigate('/player/government-id')}
          button
        >
          <ListItemIcon><DriveEta /></ListItemIcon>
          <ListItemText 
            primary="Government ID"
            primaryTypographyProps={{
              style: { fontWeight: 500 }
            }}
          />
        </ListItem>
      </List>

      <List>
        <ListItem button onClick={() => setHelpExpanded(!helpExpanded)} sx={listItemStyle}>
          <ListItemText 
            primary="Help"
            primaryTypographyProps={{
              style: { fontWeight: 500 }
            }}
          />
          {helpExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={helpExpanded} timeout="auto" unmountOnExit>
          <Box sx={expandedContentStyle}>
            <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
              If you need any assistance or have questions about registering for a Pro League Network event, 
              please don't hesitate to reach out. Email our team at{' '}
              <Typography component="span" sx={{ color: 'primary.main' }}>
                compliance@proleaguenetwork.com
              </Typography>
              , and we'll be happy to provide support and address any concerns you may have.
            </Typography>
          </Box>
        </Collapse>

        <ListItem button onClick={() => setPrivacyExpanded(!privacyExpanded)} sx={listItemStyle}>
          <ListItemText 
            primary="Privacy Policy"
            primaryTypographyProps={{
              style: { fontWeight: 500 }
            }}
          />
          {privacyExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={privacyExpanded} timeout="auto" unmountOnExit>
          <Box sx={expandedContentStyle}>
            <Typography variant="body2" sx={{ lineHeight: 1.6, mb: 2 }}>
              Pro League Network is committed to protecting your privacy and ensuring the confidentiality 
              of any personal information you provide. We will not share, sell, or distribute your personal 
              information to any third parties, except as required by law or necessary for compliance with 
              relevant athletic commissions, gaming commissions, or other governmental authorities. This 
              ensures transparency and integrity in all our sporting competitions.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.6, mb: 2 }}>
              Information collected during event registration or participation will be used solely for event 
              management, communication, and maintaining compliance with applicable regulations. By providing 
              your information, you consent to its use for these purposes. We take data security seriously 
              and implement appropriate measures to protect your personal information from unauthorized 
              access or disclosure.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
              If you have any questions about our privacy practices, please contact us at{' '}
              <Typography component="span" sx={{ color: 'primary.main' }}>
                compliance@proleaguenetwork.com
              </Typography>
              .
            </Typography>
          </Box>
        </Collapse>
      </List>
    </Container>
  );
};

export default PlayerHomePage; 