import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, Box, Divider, Button, TextField } from '@mui/material';
import { getPlayerProfile, updatePlayerWaiverInfo } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneInput from 'react-phone-number-input/input';
import CenteredLoading from '../components/CenteredLoading';

const PlayerPersonalInfo = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const playerId = localStorage.getItem('playerId');
        if (!playerId) {
          setError('Player ID not found.');
          return;
        }
        const profileData = await getPlayerProfile(playerId);
        setProfile(profileData);
      } catch (error) {
        setError('Failed to load profile data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSave = async (field, value) => {
    try {
      setSaving(true);
      const playerId = localStorage.getItem('playerId');
      
      // Create update payload with existing values
      const updatePayload = {
        phoneNumber: profile.PhoneNumber,
        address: profile.HomeAddress,
        state: profile.State,
        postal: profile.Zip
      };

      // Update the specific field
      updatePayload[field] = value;

      await updatePlayerWaiverInfo(playerId, updatePayload);
      
      // Refresh profile data after update
      const updatedProfile = await getPlayerProfile(playerId);
      setProfile(updatedProfile);
      
      // Clear edit value
      setEditValues(prev => ({
        ...prev,
        [field]: undefined
      }));

    } catch (error) {
      console.error('Failed to save:', error);
    } finally {
      setSaving(false);
    }
  };

  const InfoField = ({ label, value, field, type = 'text', required = false }) => {
    const isEditable = required && !value;
    const [localValue, setLocalValue] = useState(value || '');

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && type !== 'phone') {
        handleSave(field, localValue);
      }
    };

    const renderEditInput = () => {
      switch (type) {
        case 'phone':
          return (
            <PhoneInput
              country="US"
              value={localValue}
              onChange={(value) => setLocalValue(value)}
              style={{
                width: '100%',
                padding: '8px',
                marginTop: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          );
        default:
          return (
            <TextField
              fullWidth
              size="small"
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              onKeyPress={handleKeyPress}
              variant="outlined"
              disabled={saving}
            />
          );
      }
    };

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" color="textSecondary">
          {label}
          {isEditable && (
            <Typography 
              component="span" 
              color="error" 
              variant="caption" 
              sx={{ ml: 1 }}
            >
              (Required)
            </Typography>
          )}
        </Typography>
        {isEditable ? (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', mt: 1 }}>
            {renderEditInput()}
            <Button
              variant="contained"
              size="small"
              onClick={() => handleSave(field, localValue)}
              disabled={saving || !localValue}
              sx={{
                bgcolor: '#202026',
                '&:hover': { bgcolor: '#000000' }
              }}
            >
              Save
            </Button>
          </Box>
        ) : value ? (
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {value}
          </Typography>
        ) : null}
        <Divider sx={{ mt: 1 }} />
      </Box>
    );
  };

  if (loading) return <CenteredLoading />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="sm">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/player-home')}
        sx={{ mt: 2, mb: 2 }}
      >
        Back to Profile
      </Button>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Personal Information
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoField label="First Name" value={profile?.FirstName} />
            <InfoField label="Last Name" value={profile?.LastName} />
            <InfoField label="Email" value={profile?.Email} />
            <InfoField 
              label="Phone Number" 
              value={profile?.PhoneNumber} 
              field="phoneNumber"
              type="phone"
              required={true}
            />
            <InfoField 
              label="Address" 
              value={profile?.HomeAddress} 
              field="address"
              required={true}
            />
            <InfoField 
              label="State" 
              value={profile?.State} 
              field="state"
              required={true}
            />
            <InfoField 
              label="Postal Code" 
              value={profile?.Zip} 
              field="postal"
              required={true}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PlayerPersonalInfo; 