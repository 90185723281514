import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { updatePlayerIdInfo, uploadDrivingLicense, getPlayerProfile, checkProfileCompleteness } from '../services/apiService';
import { ReactComponent as NextArrow } from '../assets/Frame.svg';
import UploadIcon from '@mui/icons-material/Upload';
import Webcam from 'react-webcam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useMediaQuery, useTheme } from '@mui/material';

const GovernmentIdRegistration = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    legalName: '',
    dateOfBirth: '',
    idType: 'drivers_license',
    idNumber: '',
    idStateCountry: '',
    idExpirationDate: ''
  });
  const [idFile, setIdFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [useCamera, setUseCamera] = useState(false);
  const webcamRef = React.useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const idTypes = [
    { value: 'drivers_license', label: 'Drivers License' },
    { value: 'passport', label: 'Passport' },
    { value: 'id_card', label: 'ID Card' }
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdFile(file);
      const url = URL.createObjectURL(file);
      setImagePreviewUrl(url);
    }
  };

  const toggleCamera = () => {
    setUseCamera(prev => !prev);
    if (!useCamera) {
      setImagePreviewUrl(null);
    }
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImagePreviewUrl(imageSrc);
        setUseCamera(false);
        // Convert base64 to file
        fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `captured-${Date.now()}.jpg`;
            const capturedFile = new File([blob], fileName, { type: 'image/jpeg' });
            setIdFile(capturedFile);
          });
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);

    try {
      const playerId = localStorage.getItem('playerId');
      if (!playerId) {
        throw new Error('No player ID found');
      }

      // Validate required fields
      if (!formData.legalName || !formData.dateOfBirth || !formData.idNumber || 
          !formData.idStateCountry || !formData.idExpirationDate || !idFile) {
        setSnackbar({
          open: true,
          message: 'Please fill in all required fields and upload your ID',
          severity: 'error'
        });
        setIsLoading(false);
        return;
      }

      // Upload ID image
      await uploadDrivingLicense(playerId, idFile);

      // Update player info
      await updatePlayerIdInfo(playerId, {
        idType: formData.idType,
        idNumber: formData.idNumber,
        legalName: formData.legalName,
        idStateCountry: formData.idStateCountry,
        idExpirationDate: formData.idExpirationDate,
        dateOfBirth: formData.dateOfBirth
      });

      // Get updated profile and check next step
      const updatedProfile = await getPlayerProfile(playerId);
      const nextStep = checkProfileCompleteness(updatedProfile);
      
      setSnackbar({
        open: true,
        message: 'ID information saved successfully',
        severity: 'success'
      });

      setIsLoading(true);
      navigate(nextStep);

    } catch (error) {
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save ID information. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          GOVERNMENT ID
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              Legal Name as on ID
            </Typography>
            <TextField
              fullWidth
              name="legalName"
              value={formData.legalName}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                legalName: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.legalName}
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.legalName ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              ID Number
            </Typography>
            <TextField
              fullWidth
              name="idNumber"
              value={formData.idNumber}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                idNumber: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.idNumber}
              InputLabelProps={{ shrink: false }}
              InputProps={{ notched: false }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.idNumber ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              Date of Birth
            </Typography>
            <TextField
              fullWidth
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                dateOfBirth: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.dateOfBirth}
              InputLabelProps={{ shrink: true }}
              InputProps={{ notched: false }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.dateOfBirth ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              Type of ID
            </Typography>
            <TextField
              select
              fullWidth
              name="idType"
              value={formData.idType}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                idType: e.target.value
              }))}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px'
                }
              }}
            >
              {idTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              State or Country of Issue
            </Typography>
            <TextField
              fullWidth
              name="idStateCountry"
              value={formData.idStateCountry}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                idStateCountry: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.idStateCountry}
              InputLabelProps={{ shrink: false }}
              InputProps={{ notched: false }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.idStateCountry ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.25,
              display: 'block',
              lineHeight: 1
            }}>
              Expiry Date of ID
            </Typography>
            <TextField
              fullWidth
              type="date"
              name="idExpirationDate"
              value={formData.idExpirationDate}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                idExpirationDate: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.idExpirationDate}
              InputLabelProps={{ shrink: true }}
              InputProps={{ notched: false }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.idExpirationDate ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            {imagePreviewUrl && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                mb: 2
              }}>
                <Button 
                  sx={{ color: 'error.main' }}
                  onClick={() => {
                    setImagePreviewUrl(null);
                    setIdFile(null);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={toggleCamera}>Edit Photo</Button>
                <Button sx={{ color: 'success.main' }}>Done</Button>
              </Box>
            )}

            <Box sx={{ 
              width: '100%',
              height: imagePreviewUrl ? '300px' : '200px',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              position: 'relative'
            }}>
              {useCamera ? (
                <>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{ 
                      facingMode: isMobile ? { exact: "environment" } : "user",
                      width: 1280,
                      height: 720
                    }}
                    style={{
                      width: '100%',
                      borderRadius: '8px'
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={captureImage}
                    sx={{ position: 'absolute', bottom: 16 }}
                  >
                    Capture Photo
                  </Button>
                </>
              ) : imagePreviewUrl ? (
                <img 
                  src={imagePreviewUrl} 
                  alt="ID Preview" 
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain'
                  }}
                />
              ) : (
                <>
                  <UploadIcon sx={{ fontSize: 40, color: '#666', mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 2, color: '#666' }}>
                    Upload your ID photo
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<UploadIcon />}
                    >
                      Select Photo
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        hidden
                      />
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={toggleCamera}
                      startIcon={<PhotoCameraIcon />}
                    >
                      Use Camera
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Button
            type="button"
            onClick={handleSubmit}
            variant="contained"
            sx={{
              bgcolor: '#202026',
              borderRadius: '100px',
              padding: '12px 24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'uppercase',
              width: '156px',
              margin: '24px auto',
              '&:hover': {
                bgcolor: '#000000'
              }
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <span>Next</span>
                <Box sx={{ 
                  bgcolor: 'white',
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <NextArrow />
                </Box>
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GovernmentIdRegistration;