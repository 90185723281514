import React, { useState, useCallback, useEffect } from 'react';
import { Container, Button, Typography, IconButton, Slider, Box, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ImageIcon from '@mui/icons-material/Image';
import CropIcon from '@mui/icons-material/Crop';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FlipIcon from '@mui/icons-material/Flip';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../components/cropImage'; // Helper function to handle image cropping
import { uploadHeadshot as uploadHeadshotAPI } from '../services/apiService'; // Adjust the import path as necessary
import { useNavigate, useLocation } from 'react-router-dom';

const HeadShotUploadPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Add this
  const isRegistrationFlow = location.pathname.includes('/registration'); // Check if we're in registration flow
  const [playerId, setPlayerId] = useState(null); // State for playerId
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [flip, setFlip] = useState({ horizontal: false, vertical: false });
  const [useCamera, setUseCamera] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null); // Store the file object
  const webcamRef = React.useRef(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [isLoading, setIsLoading] = useState(false);

  // Retrieve playerId from local storage when the component mounts
  useEffect(() => {
    const storedPlayerId = localStorage.getItem('playerId');
    if (storedPlayerId) {
      setPlayerId(storedPlayerId);
      console.log('Player ID retrieved from local storage:', storedPlayerId);
    } else {
      console.error('Player ID not found in local storage');
    }
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreviewUrl(previewUrl);
      setCropMode(true);
      setFile(selectedFile);
      setUseCamera(false); // Ensure camera mode is turned off when uploading from file
    }
  };
  
  const toggleCamera = () => {
    setUseCamera((prev) => !prev);
    if (!useCamera) {
      setImagePreviewUrl(null); // Clear preview if switching back to camera
    }
  };
  


  const captureImage = () => {
    if (webcamRef.current) { // Add this check to prevent errors
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImagePreviewUrl(imageSrc);
        setCropMode(true);
        setUseCamera(false);
        fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `captured-${Date.now()}.jpg`;
            const capturedFile = new File([blob], fileName, { type: 'image/jpeg' });
            setFile(capturedFile);
          });
      }
    } else {
      console.error('Webcam is not active or not available.');
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Keep existing validation
    if (!file && !imagePreviewUrl) {
      setSnackbar({
        open: true,
        message: 'Please take or upload a photo',
        severity: 'error'
      });
      return;
    }

    setIsLoading(true);
    try {
      const playerId = localStorage.getItem('playerId');
      if (!playerId) {
        throw new Error('No player ID found');
      }

      await uploadHeadshotAPI(playerId, file);
      
      setSnackbar({
        open: true,
        message: 'Photo uploaded successfully',
        severity: 'success'
      });

      // Only show thank you page in registration flow
      if (isRegistrationFlow) {
        setTimeout(() => {  // Add small delay to show success message
          navigate('/registration/complete', { 
            state: { fromRegistration: true }
          });
        }, 500);
      } else {
        navigate('/admin');
      }
      // Don't setIsLoading(false) here - let it persist through navigation

    } catch (error) {
      console.error('Error uploading headshot:', error);
      setSnackbar({
        open: true,
        message: 'Failed to upload photo. Please try again.',
        severity: 'error'
      });
      setIsLoading(false);  // Only clear loading on error
    }
  };

  const handleFlip = (axis) => {
    setFlip((prev) => ({
      ...prev,
      [axis]: !prev[axis],
    }));
  };

  const getTransformStyles = () => {
    let transform = '';
    if (flip.horizontal) transform += 'scaleX(-1) ';
    if (flip.vertical) transform += 'scaleY(-1) ';
    transform += `rotate(${rotation}deg)`;
    return transform.trim();
  };

  // const showSkipButton = window.location.search.includes('registration=true');
  const isRegistration = window.location.search.includes('registration=true');

  const handleSkip = () => {
    navigate('/registration/complete');
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {isRegistrationFlow ? 'REGISTRATION: HEADSHOT PHOTO' : 'HEADSHOT UPLOAD'}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
          {!isRegistrationFlow && (
            <Button color="error" startIcon={<CloseIcon />} sx={{ textTransform: 'none' }} onClick={handleSkip}>
              Cancel
            </Button>
          )}
          <Button color="success" startIcon={<DoneIcon />} sx={{ textTransform: 'none' }} onClick={handleSubmit}>
            Done
          </Button>
        </Box>

        {isRegistrationFlow && (
          <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500', mb: 2 }}>
            Turn your camera on and take a recent photo
          </Typography>
        )}

        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#ccc',
            width: '100%',
            height: 0,
            paddingBottom: '100%',
            position: 'relative',
          }}
        >
          {imagePreviewUrl ? (
            cropMode ? (
              <Cropper
                image={imagePreviewUrl}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                cropShape="round"
                showGrid={false}
                style={{
                  containerStyle: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    borderRadius: '50%',
                    transform: getTransformStyles(),
                  },
                }}
              />
            ) : (
              <img
                src={imagePreviewUrl}
                alt="Headshot Preview"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  transform: getTransformStyles(),
                }}
              />
            )
          ) : useCamera ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{ facingMode: 'user' }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <IconButton
              component="label"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <ImageIcon fontSize="large" />
              <input type="file" hidden accept="image/*" onChange={handleFileChange} />
            </IconButton>
          )}
        </Box>

        {/* Editing Toolbar */}
        <Box display="flex" justifyContent="space-between" maxWidth="100%" marginTop="20px">
          <IconButton onClick={() => setUseCamera((prev) => !prev)}>
            <PhotoCameraIcon />
          </IconButton>
          <IconButton onClick={() => setCropMode((prev) => !prev)}>
            <CropIcon />
          </IconButton>
          <IconButton onClick={() => handleFlip('horizontal')}>
            <FlipIcon style={{ transform: 'scaleX(-1)' }} />
          </IconButton>
          <IconButton onClick={() => handleFlip('vertical')}>
            <FlipIcon style={{ transform: 'scaleY(-1)' }} />
          </IconButton>
          <IconButton onClick={() => setRotation((prev) => prev + 90)}>
            <RotateRightIcon />
          </IconButton>
        </Box>

        {cropMode && (
          <Box marginTop="10px">
            <Typography gutterBottom>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
        )}

        {useCamera && (
          <Button
            variant="contained"
            color="primary"
            onClick={captureImage}
            sx={{ marginTop: '10px', textTransform: 'none' }}
          >
            Capture Photo
          </Button>
        )}
        {errorMessage && (
          <Typography color="error" sx={{ marginTop: '10px' }}>
            {errorMessage}
          </Typography>
        )}

        {/* Modify buttons based on flow */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {!isRegistrationFlow && (
            <Button onClick={() => navigate(-1)}>
              Cancel
            </Button>
          )}
          <Button 
            onClick={handleSubmit}
            variant="contained"
            sx={{
              bgcolor: '#202026',
              borderRadius: '100px',
              padding: '12px 24px',
              textTransform: 'uppercase',
              '&:hover': {
                bgcolor: '#000000'
              }
            }}
          >
            {isRegistrationFlow ? 'Complete Registration' : 'Save'}
          </Button>
        </Box>
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default HeadShotUploadPage;
