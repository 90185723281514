import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, CircularProgress, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RegistrationComplete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear registration flags when complete
    localStorage.removeItem('fromSignup');
  }, []);

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: 3
        }}
      >
        <CheckCircleIcon 
          sx={{ 
            fontSize: 60, 
            color: 'success.main'
          }} 
        />
        
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Registration Complete!
        </Typography>

        <Typography variant="body1" sx={{ maxWidth: '600px', lineHeight: 1.6 }}>
          Your registration for the Pro League Network event has been successfully received. 
          We'll be in touch soon with more details about the event, including waivers and 
          information on upcoming activities.
        </Typography>

        <Button
          variant="contained"
          onClick={() => navigate('/player-home')}
          sx={{
            bgcolor: '#202026',
            borderRadius: '100px',
            padding: '12px 24px',
            textTransform: 'uppercase',
            '&:hover': {
              bgcolor: '#000000'
            }
          }}
        >
          Go to Profile
        </Button>
      </Box>
    </Container>
  );
};

export default RegistrationComplete;