import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { signup, login } from '../services/apiService';
import { useLoadingError } from '../AppContext';
import { useAuth } from '../AppContext';

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const SignupForm = ({ isModal = false, onSuccess }) => {
    const [userData, setUserData] = useState({
        FirstName: '',
        LastName: '',
        Email: '',
        Password: ''
    });
    const { loading, error, setLoading, setError } = useLoadingError();
    const { setUser } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const payload = {
                ...userData,
                UserName: userData.Email,
            };
            const signupResponse = await signup(payload);
            console.log('Signup response:', signupResponse);
            
            const loginResponse = await login({
                EmailOrUserName: userData.Email,
                Password: userData.Password
            });
            
            localStorage.setItem('token', loginResponse);
            localStorage.setItem('playerId', signupResponse.player.PlayerId);
            localStorage.setItem('fromSignup', 'true');
            setUser({ token: loginResponse });
            
            if (isModal && onSuccess) {
                onSuccess();
            } else {
                navigate('/registration/player-info', { 
                    state: { fromSignup: true }
                });
            }
        } catch (error) {
            console.error('Signup/Login failed:', error);
            setError('Signup failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            ...(isModal ? { mt: 2 } : { marginTop: 8 })
        }}>
            {!isModal && <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />}
            {!isModal && <Typography component="h1" variant="h5">Sign Up</Typography>}
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="FirstName"
                    label="First Name"
                    value={userData.FirstName}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="LastName"
                    label="Last Name"
                    value={userData.LastName}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="Email"
                    label="Email"
                    value={userData.Email}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="Password"
                    label="Password"
                    type="password"
                    value={userData.Password}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Sign Up'}
                </Button>
                {error && (
                    <Typography color="error" align="center">{error}</Typography>
                )}
            </Box>
        </Box>
    );
};

export default SignupForm;
