import React from 'react';
import SignupForm from '../components/SignupForm'; // Adjust the path according to your directory structure
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
    const navigate = useNavigate();

    const handleSignupSuccess = () => {
        localStorage.setItem('fromSignup', 'true');
        navigate('/registration/player-info', { 
            state: { fromSignup: true }
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <SignupForm onSignupSuccess={handleSignupSuccess} />
        </Container>
    );
};

export default SignupPage;
