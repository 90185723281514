import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { login, stopSessionMaintenance, getPlayerProfile, checkProfileCompleteness } from '../services/apiService';
import { useAuth, useLoadingError } from '../AppContext';

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const LoginForm = ({ isModal = false, onSuccess }) => {
    const [credentials, setCredentials] = useState({
        EmailOrUserName: '',
        Password: ''
    });
    const { setUser } = useAuth();
    const { loading, error, setLoading, setError } = useLoadingError();
    const navigate = useNavigate();

    useEffect(() => {
        stopSessionMaintenance();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await login(credentials);
            setUser({ token: localStorage.getItem('token') });
            
            if (localStorage.getItem('roleName') === 'Player') {
                const profile = await getPlayerProfile(localStorage.getItem('playerId'));
                const nextStep = checkProfileCompleteness(profile);
                navigate(nextStep);
            } else {
                navigate('/admin');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials and try again.');
        } finally {
            setLoading(false);
        }
    };

    const FormContent = (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            ...(isModal ? { mt: 2 } : { marginTop: 8 })
        }}>
            {!isModal && <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />}
            {!isModal && <Typography component="h1" variant="h5">Sign in</Typography>}
            
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="EmailOrUserName"
                    label="Email or Username"
                    name="EmailOrUserName"
                    autoComplete="email"
                    autoFocus
                    value={credentials.EmailOrUserName}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="Password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={credentials.Password}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Sign In'}
                </Button>
                {error && (
                    <Typography color="error" align="center">{error}</Typography>
                )}
            </Box>
            
            {!isModal && (
                // Only show these elements in full page mode
                <>
                    <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                        <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'primary.main' }}>
                            Forgot Password?
                        </Link>
                    </Typography>
                    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="body2">
                            Don't have an account? <Link to="/signup">Sign Up</Link>
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );

    return isModal ? FormContent : (
        <Container component="main" maxWidth="xs">
            {FormContent}
        </Container>
    );
};

export default LoginForm;
