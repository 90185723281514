import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Paper, 
  Box, 
  Divider, 
  Button, 
  TextField,
  MenuItem,
  Select
} from '@mui/material';
import { getPlayerProfile, updatePlayerIdInfo, uploadDrivingLicense } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneInput from 'react-phone-number-input/input';
import UploadIcon from '@mui/icons-material/Upload';
import CenteredLoading from '../components/CenteredLoading';

const PlayerGovernmentIdView = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const playerId = localStorage.getItem('playerId');
        if (!playerId) {
          setError('Player ID not found.');
          return;
        }
        const profileData = await getPlayerProfile(playerId);
        setProfile(profileData);
      } catch (error) {
        setError('Failed to load profile data.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSave = async (field, value, file = null) => {
    try {
      setSaving(true);
      const playerId = localStorage.getItem('playerId');
      
      // Handle file upload first if present
      if (file) {
        await uploadDrivingLicense(playerId, file);
      }

      // Map field names to API field names
      const fieldMapping = {
        'legalName': 'LegalName',
        'dateOfBirth': 'DOB',
        'idType': 'IDType',
        'idNumber': 'IDNumber',
        'idStateCountry': 'IDCountry',
        'idExpirationDate': 'IDExpirationDate'
      };

      // Create update payload with existing values
      const updatePayload = {
        idType: profile.IDType,
        idNumber: profile.IDNumber,
        legalName: profile.LegalName,
        idStateCountry: profile.IDCountry,
        idExpirationDate: profile.IDExpirationDate,
        dateOfBirth: profile.DOB
      };

      // Update the specific field
      updatePayload[field] = value;

      await updatePlayerIdInfo(playerId, updatePayload);
      
      // Refresh profile data after update
      const updatedProfile = await getPlayerProfile(playerId);
      setProfile(updatedProfile);
      
      // Clear edit value
      setEditValues(prev => ({
        ...prev,
        [field]: undefined
      }));

    } catch (error) {
      console.error('Failed to save:', error);
    } finally {
      setSaving(false);
    }
  };

  const InfoField = ({ label, value, field, type = 'text', required = false }) => {
    const isEditable = required && !value;
    const [localValue, setLocalValue] = useState(value || '');
    const [file, setFile] = useState(null);

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && type !== 'select') {
        handleSave(field, localValue);
      }
    };

    const renderEditInput = () => {
      switch (type) {
        case 'date':
          return (
            <TextField
              fullWidth
              type="date"
              size="small"
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              onKeyPress={handleKeyPress}
              variant="outlined"
              disabled={saving}
              InputLabelProps={{ shrink: true }}
            />
          );

        case 'phone':
          return (
            <PhoneInput
              value={localValue}
              onChange={(value) => setLocalValue(value)}
              style={{
                width: '100%',
                padding: '8px',
                marginTop: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px'
              }}
            />
          );

        case 'select':
          const idTypes = [
            { value: 'drivers_license', label: "Driver's License" },
            { value: 'passport', label: 'Passport' },
            { value: 'id_card', label: 'ID Card' }
          ];
          return (
            <Select
              fullWidth
              size="small"
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              disabled={saving}
            >
              {idTypes.map(type => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          );

        case 'file':
          return (
            <Box sx={{ 
              width: '100%',
              height: '100px',
              border: '2px dashed #ccc',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                borderColor: '#999'
              }
            }}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFile(file);
                    handleSave(field, null, file);
                  }
                }}
                style={{ display: 'none' }}
                id={`file-input-${field}`}
              />
              <label htmlFor={`file-input-${field}`}>
                <UploadIcon sx={{ fontSize: 40, color: '#666', mb: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  Click to upload
                </Typography>
              </label>
            </Box>
          );

        default:
          return (
            <TextField
              fullWidth
              size="small"
              value={localValue}
              onChange={(e) => setLocalValue(e.target.value)}
              onKeyPress={handleKeyPress}
              variant="outlined"
              disabled={saving}
            />
          );
      }
    };

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" color="textSecondary">
          {label}
          {isEditable && (
            <Typography 
              component="span" 
              color="error" 
              variant="caption" 
              sx={{ ml: 1 }}
            >
              (Required)
            </Typography>
          )}
        </Typography>
        {isEditable ? (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', mt: 1 }}>
            {renderEditInput()}
            {type !== 'file' && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSave(field, localValue)}
                disabled={saving || !localValue}
                sx={{
                  bgcolor: '#202026',
                  '&:hover': { bgcolor: '#000000' }
                }}
              >
                Save
              </Button>
            )}
          </Box>
        ) : value ? (
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {type === 'select' ? formatIdType(value) : value}
          </Typography>
        ) : null}
        <Divider sx={{ mt: 1 }} />
      </Box>
    );
  };

  const formatIdType = (type) => {
    if (!type) return null;
    const types = {
      'drivers_license': "Driver's License",
      'passport': 'Passport',
      'id_card': 'ID Card'
    };
    return types[type] || type;
  };

  if (loading) return <CenteredLoading />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="sm">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/player-home')}
        sx={{ mt: 2, mb: 2 }}
      >
        Back to Profile
      </Button>

      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Government ID Information
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoField 
              label="Legal Name" 
              value={profile?.LegalName} 
              field="legalName"
              required={true}
            />
            <InfoField 
              label="Date of Birth" 
              value={profile?.DOB} 
              field="dateOfBirth"
              type="date"
              required={true}
            />
            <InfoField 
              label="ID Type" 
              value={profile?.IDType}
              field="idType"
              type="select"
              required={true}
            />
            <InfoField 
              label="ID Number" 
              value={profile?.IDNumber} 
              field="idNumber"
              required={true}
            />
            <InfoField 
              label="ID State/Country" 
              value={profile?.IDCountry} 
              field="idStateCountry"
              required={true}
            />
            <InfoField 
              label="ID Expiration Date" 
              value={profile?.IDExpirationDate} 
              field="idExpirationDate"
              type="date"
              required={true}
            />
          </Grid>

          {!profile?.DrivingLicenseURL && (
            <Grid item xs={12}>
              <InfoField 
                label="ID Document" 
                value={profile?.DrivingLicenseURL} 
                field="drivingLicense"
                type="file"
                required={true}
              />
            </Grid>
          )}

          {profile?.DrivingLicenseURL && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                ID Document
              </Typography>
              <Box
                component="img"
                src={profile.DrivingLicenseURL}
                alt="Government ID"
                sx={{
                  width: '100%',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  borderRadius: 1
                }}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default PlayerGovernmentIdView; 