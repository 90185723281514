import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  MenuItem
} from '@mui/material';
import { updatePlayerWaiverInfo, getPlayerProfile } from '../services/apiService';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { ReactComponent as NextArrow } from '../assets/Frame.svg';
import StateSelect from '../components/StateSelect';

const PlayerInfoRegistration = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    suite: '',
    state: '',
    postal: '',
    city: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Check for playerId on mount
  useEffect(() => {
    const playerId = localStorage.getItem('playerId');
    if (!playerId) {
      navigate('/signup');
      return;
    }

    const fetchProfile = async () => {
      try {
        const profile = await getPlayerProfile(playerId);
        setFormData(prev => ({
          ...prev,
          firstName: profile.FirstName || '',
          lastName: profile.LastName || '',
          email: profile.Email || '',
        }));
      } catch (error) {
        console.error('Error fetching profile:', error);
        setSnackbar({
          open: true,
          message: 'Error loading profile data',
          severity: 'error'
        });
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    
    try {
      const playerId = localStorage.getItem('playerId');
      if (!playerId) {
        throw new Error('No player ID found');
      }

      // Validate required fields
      if (!formData.phoneNumber || !formData.address || !formData.state || 
          !formData.postal || !formData.city) {
        setSnackbar({
          open: true,
          message: 'Please fill in all required fields',
          severity: 'error'
        });
        setIsLoading(false);
        return;
      }

      await updatePlayerWaiverInfo(playerId, formData);
      navigate('/registration/government-id', { 
        state: { fromRegistration: true }
      });
    } catch (error) {
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save information. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Personal Info
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              First Name
            </Typography>
            <TextField
              fullWidth
              name="firstName"
              value={formData.firstName}
              disabled={true}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
                sx: { backgroundColor: '#f5f5f5' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              Last Name
            </Typography>
            <TextField
              fullWidth
              name="lastName"
              value={formData.lastName}
              disabled={true}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
                sx: { backgroundColor: '#f5f5f5' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              Email
            </Typography>
            <TextField
              fullWidth
              name="email"
              value={formData.email}
              disabled={true}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
                sx: { backgroundColor: '#f5f5f5' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              Mobile Phone number
            </Typography>
            <PhoneInput
              country="US"
              value={formData.phoneNumber}
              onChange={value => setFormData(prev => ({
                ...prev,
                phoneNumber: value
              }))}
              style={{
                width: '100%',
                padding: '16.5px 14px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '8px',
                fontSize: '1rem',
                backgroundColor: 'transparent',
                '&:hover': {
                  borderColor: '#000000'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              Address
            </Typography>
            <TextField
              fullWidth
              name="address"
              value={formData.address}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                address: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.address}
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
              }}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.address ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              Suite (Optional)
            </Typography>
            <TextField
              fullWidth
              name="suite"
              value={formData.suite}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                suite: e.target.value
              }))}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              InputProps={{ 
                notched: false,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px'
                }
              }}
            />
          </Box>

          <Box>
            <Typography variant="caption" sx={{ 
              color: 'black',
              mb: 0.5,
              display: 'block',
              lineHeight: 1
            }}>
              City
            </Typography>
            <TextField
              fullWidth
              name="city"
              value={formData.city}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                city: e.target.value
              }))}
              variant="outlined"
              error={isSubmitted && !formData.city}
              InputLabelProps={{ shrink: false }}
              InputProps={{ notched: false }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  height: '45px',
                  borderRadius: '8px',
                  border: (theme) => isSubmitted && !formData.city ? 
                    `1px solid ${theme.palette.error.main}` : 'none'
                }
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <StateSelect
              value={formData.state}
              onChange={(newValue) => setFormData(prev => ({
                ...prev,
                state: newValue
              }))}
              error={isSubmitted && !formData.state}
              isSubmitted={isSubmitted}
            />

            <Box sx={{ flex: 1 }}>
              <Typography variant="caption" sx={{ 
                color: 'black',
                mb: 0.5,
                display: 'block',
                lineHeight: 1
              }}>
                Postal
              </Typography>
              <TextField
                name="postal"
                value={formData.postal}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  postal: e.target.value
                }))}
                variant="outlined"
                error={isSubmitted && !formData.postal}
                InputLabelProps={{ shrink: false }}
                InputProps={{ notched: false }}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'transparent',
                    height: '45px',
                    borderRadius: '8px',
                    border: (theme) => isSubmitted && !formData.postal ? 
                      `1px solid ${theme.palette.error.main}` : 'none'
                  }
                }}
              />
            </Box>
          </Box>

          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: '#202026',
              borderRadius: '100px',
              padding: '12px 24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'uppercase',
              width: { xs: '100%', sm: '156px' },
              margin: { xs: '24px 0', sm: '24px auto' },
              '&:hover': {
                bgcolor: '#000000'
              }
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <span>Next</span>
                <Box sx={{ 
                  bgcolor: 'white',
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <NextArrow />
                </Box>
              </>
            )}
          </Button>
        </Box>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default PlayerInfoRegistration;